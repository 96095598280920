import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { SEO } from '../components/SEO'

const Index = ({data}) => {
  const { edges } = data.allMarkdownRemark

  return (
    <>
    <SEO title='Entuit Enterprise Solutions Inc.'/>
    <Header />
      <div className="container mx-auto mt-8">
        <div className="bg-white shadow overflow-hidden sm:rounded-md">
        <ul className="divide-y divide-gray-200">
          {edges.map(edge => {
            const {frontmatter} = edge.node
            return (
              <li key={frontmatter.path}>
                <Link to={frontmatter.path} className="block hover:bg-gray-50">
                  <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                      <div className="flex-shrink-0">
                        <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} className="h-12 w-12 rounded-full" />
                      </div>
                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                        <div>
                          <p className="text-sm font-medium text-gray-700 truncate">{frontmatter.title}</p>
                          <p className="mt-2 flex items-center text-sm text-gray-500">
                            {frontmatter.date}
                          </p>
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div>
                          <p className="text-sm text-gray-900">{frontmatter.excerpt}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                    <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default Index

export const query = graphql`
  query HomepageQuery {
    allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            path
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`